$bg-color: white;
$header-color: #282c34;
$y300: #fff59d;
$fadeTime:3.0s;

$main-color:#1376e3;
.App {
  text-align: center;
  background-color:$bg-color;
  display: flex;
  // flex-direction: column;
  height: 100vh;
}
.row-drcn {
  display: flex;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $header-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ql-editor {
  min-height: 20rem;
}

/* Styles for the editor container */
.editor-container {
  position: relative;
  width: 100%;
  height: 500px; /* Set the desired height */
}

/* Styles for the toolbar */
.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: #f0f0f0; /* Customize toolbar background color */
  z-index: 1; /* Ensure the toolbar is above the content */
}

/* Styles for the content area */
.ql-container {
  height: calc(100% - 40px); /* Subtract toolbar height from the container */
  overflow-y: auto; /* Allow the content to scroll */
  border: 1px solid #ddd; /* Add borders or other styles as desired */
  padding: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items:center;
}

// reac big callendar
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 1px solid #e0e0e0;
}
.rbc-overlay {
  background-color: $y300 !important;
  display: none; // popup 차단
}
body > div.rbc-overlay > div {
  background-color: $y300 !important;
}
.rbc-overlay-header {
  font-size:15px;
  font-weight: bold;
}
.rbc-toolbar button {
  color:$main-color !important;
  border-color:$main-color !important;
}

@font-face {
  font-family: JejuGothic;
  src: url('../fonts/JejuGothic.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Regular.ttf');
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fadeOut{
     opacity:0;
     width:0;
     height:0;
     // transition: width 0.5s 0.5s, height 1.0s 0.5s, opacity 0.5s;
     transition: width $fadeTime $fadeTime, height $fadeTime $fadeTime, opacity $fadeTime;

}
.fadeIn{
     opacity:1;
     transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}

$bg-color: white;
$header-color: #282c34;
$y300: #fff59d;
$fadeTime:3.0s;

$main-color:#1376e3;
.App {
  text-align: center;
  background-color:$bg-color;
  display: flex;
  // flex-direction: column;
  height: 100vh;
}
.row-drcn {
  display: flex;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $header-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ql-editor {
  min-height: 20rem;
}

/* Styles for the editor container */
.editor-container {
  position: relative;
  width: 100%;
  height: 500px; /* Set the desired height */
}

/* Styles for the toolbar */
.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: #f0f0f0; /* Customize toolbar background color */
  z-index: 1; /* Ensure the toolbar is above the content */
}

/* Styles for the content area */
.ql-container {
  height: calc(100% - 40px); /* Subtract toolbar height from the container */
  overflow-y: auto; /* Allow the content to scroll */
  border: 1px solid #ddd; /* Add borders or other styles as desired */
  padding: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items:center;
}

// reac big callendar
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 1px solid #e0e0e0;
}
.rbc-overlay {
  background-color: $y300 !important;
  display: none; // popup 차단
}
body > div.rbc-overlay > div {
  background-color: $y300 !important;
}
.rbc-overlay-header {
  font-size:15px;
  font-weight: bold;
}
.rbc-toolbar button {
  color:$main-color !important;
  border-color:$main-color !important;
}

@font-face {
  font-family: JejuGothic;
  src: url('../fonts/JejuGothic.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Regular.ttf');
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fadeOut{
     opacity:0;
     width:0;
     height:0;
     // transition: width 0.5s 0.5s, height 1.0s 0.5s, opacity 0.5s;
     transition: width $fadeTime $fadeTime, height $fadeTime $fadeTime, opacity $fadeTime;

}
.fadeIn{
     opacity:1;
     transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}
